var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "modal-notifications",
      attrs: { "hide-footer": "", title: _vm.title },
    },
    [
      _c(
        "div",
        { staticClass: "modal-notifications" },
        [
          _c("div", { staticClass: "d-block text-center" }, [
            _c("p", { domProps: { innerHTML: _vm._s(_vm.msg) } }),
          ]),
          _c("br"),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: { variant: "outline-danger", block: "" },
              on: { click: _vm.hideModal },
            },
            [_vm._v(_vm._s(_vm.closeText))]
          ),
          _vm.eventId != 0
            ? _c("div", { staticClass: "d-block text-right text-small" }, [
                _c("small", [_vm._v("event " + _vm._s(_vm.eventId))]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }